.drawerContent {
  transition: top 0.3s ease;
}

.open {
  top: 0;
}

.opening {
  top: 100%;
}

.closing {
  top: 100%;
}
