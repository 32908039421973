.external-link {
  font-size: 14px;
  color: #1e749f;
  display: flex;
  &.secondary {
    color: #6c6c6c;
  }
  &:hover {
    text-decoration: none;
    color: #1e749f;
  }
}

.icon {
  margin-right: 4px;
  svg {
    width: 14px;
    height: 14px;
    stroke-width: 2;
  }
}
