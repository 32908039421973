.header {
  border-right: 1px solid var(--color-text-primary);
}

.description {
  border-bottom: 1px solid var(--color-text-primary);
  height: 60px;
}

.most {
  border-bottom: 1px solid var(--color-text-primary);
}

@media (max-width: 600px) {
  .description {
    padding: 0 0 0 16px;
    background-color: inherit;
    text-align: left;
    justify-content: flex-start;
    border-bottom: 0 solid var(--color-text-primary);
    border-right: 1px solid var(--color-text-primary);
  }

  .most {
    border-bottom: 0 solid var(--color-text-primary);
    border-right: 1px solid var(--color-text-primary);
  }

  .container {
    flex-direction: column;
    overflow: visible;
  }

  .row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    flex-direction: row;
    border-bottom: 1px solid var(--color-text-primary);
    font-weight: 300;
  }

  .row:first-child {
    font-weight: 400;
  }

  .row:last-child {
    border-bottom: 0 solid var(--color-text-primary);
  }

  .row .header {
    border-right: 1px solid var(--color-text-primary);
  }
}
