.wrapper {
  padding: 24px;
  width: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}

.title {
  font-style: normal;
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: blue;
}

.description {
  font-style: normal;
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: magenta;
}

.description-secondary {
  font-style: normal;
  font-family: var(--font-lato);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: magenta;
  display: flex;
  margin-top: 8px;
}

.description-secondary span {
  font-style: italic;
}

.button-footer {
  padding: 18px 0px 16px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 4px 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-external-link {
  margin-left: 8px;
}
