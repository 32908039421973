.wrapper {
  width: 100%;
  position: relative;
  padding: 32px 0;
}

.add-people-background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  background-color: rgba($blue, 0.12);
  box-shadow: inset 0 -12px 12px 0 rgba(0, 0, 0, 0.03);
}

@media (max-width: 800px) {
  .center-small {
    text-align: center;
    padding: 24px 0;
  }
  .center-small .title {
    margin-right: 0;
  }
}

@media (max-width: 750px) {
  .add-people-background {
    height: 100%;
    width: auto;
  }
}

.add-people-background-img {
  width: 100%;
  position: absolute;
  top: -50%;
}
