.container {
  padding: 12px 0 64px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uncontained {
  display: block;
  align-items: inherit;
  justify-content: inherit;
  flex: inherit;
}

.layout {
  padding: 24px 88px;
  display: flex;
  gap: 46px;
}

.header {
  color: magenta;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.content {
  padding: 28px 0;
  text-align: center;
}

.sub-content {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.logo {
  width: 100px;
  height: 100px;
}

.card-column-1 {
  display: flex;
  align-items: center;
  height: 200px;
}

.disabled {
  pointer-events: none;
  color: grey;
  cursor: default;
}
