.page {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 2px;
  padding: 4px 8px;
  border-radius: 3px;
  color: var(--color-pagination-character);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s;
}

.page:hover {
  background-color: var(--color-pagination-hovered);
}

.current {
  background-color: var(--color-pagination-current);
  color: white;
}

.arrow {
  margin: 0 4px;
  border-radius: 4px;
  padding: 4px;
}

.disabled {
  pointer-events: none;
  color: #bbb;
  filter: saturate(0);
}
