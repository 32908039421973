.closeBtn {
  background-color: transparent;
  color: var(--color-text-primary);
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 2rem;
  font-weight: 300;
}

.closeBtn:after {
  content: '\d7';
}

.closeBtn:active {
  opacity: 0.5;
}
