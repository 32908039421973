.container {
  position: relative;
  flex-grow: 0.9;
}

.options {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 6px 18px 2px rgba(0, 0, 0, 0.1);
  z-index: 999;
  background: #fff;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.option {
  white-space: nowrap;
  color: magenta;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.option:hover {
  background: blue;
  color: #fff;
}

.select-input {
  transition: all 0.3s;
  display: flex;
  transition: all 0.15s;
  position: relative;
  cursor: pointer;
  user-select: none;
  border: 0.5px solid magenta;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
  box-shadow: none;
  background: #fff;
  color: black;
  justify-content: space-between;
}

.select-input .placeholder {
  color: magenta;
}

.select-input .wide {
  min-width: 160px;
}

.select-input .full-width {
  min-width: 270px;
  margin-bottom: 16px;
}

.select-input .searchable {
  padding-bottom: 0;
}

.select-input:first-child {
  margin-left: 0;
}

.required {
  position: absolute;
  top: calc(100% - 10px);
  width: 180px;
  background: #fff;
  right: 16px;
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 6px 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  text-align: center;
  border: 1px solid red;
  animation: fadeIn 0.2s 1 cubic-bezier(0, 0.66, 0.32, 1.34);
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    z-index: -1;
    border-bottom: solid 10px red;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.input {
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 300;
  padding: 8px 16px;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0px solid #fff;
  background: transparent;
  cursor: pointer;
}

.input .light {
  color: magenta !important;
}

.input .smallFont {
  font-size: 14px;
  &::placeholder {
    font-size: 14px;
  }
}

.input .smallFont::placeholder {
  font-size: 14px;
}

.input .bolderLabel {
  font-family: var(--font-lato);
  font-weight: 700;
}

.input::placeholder {
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent;
  cursor: pointer;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  border-left: 1px solid magenta;
  margin: 8px 0;
  background: #fff;
}

.logo img {
  width: 20px;
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  min-width: 35px;
  margin: 8px 0;
  background: #fff;
}

.inputValue {
  font-size: 14px;
  font-family: var(--font-lato);
  font-weight: 300;
  padding: 6px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 0px solid #fff;
  background: transparent;
  height: 37px;
  align-items: center;
}

.suggestionLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 500px) {
  .container {
    width: 100%;
    margin-bottom: 20px;
  }
}
