.full-page-bg {
  min-height: calc(100vh - 86px);
}

.bubble {
  border-radius: 50%;
  background-color: magenta;
  left: -2px;
  top: -1px;
}

.bubble:last-child {
  left: unset;
  right: -2px;
}

@media (min-width: 1080px) {
  .withSideNav {
    width: calc(100% - 300px);
  }
}


