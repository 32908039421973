.container {
  transition: all 0.12s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: italic;
  padding: 6px 0;
  color: var(--color-link);
}

.container:hover {
  text-decoration: none;
  transform: scale(1.05);
}

.container:focus {
  text-decoration: none;
  transform: scale(1.05);
}

.disabled,
.loading {
  color: var(--color-text-secondary);
  pointer-events: none;
}

.img {
  width: 18px;
  height: 18px;
  margin-left: 6px;
}

.img svg {
  width: 18px;
  height: 18px;
}

.back {
  transform: rotate(180deg);
  margin-left: 0;
  margin-right: 6px;
}
