.radio-button {
  border-radius: 50%;
  border: 1px solid #ccc;
  background: #fff;
}

.radio-button--selected .radio-button__fill {
  transform: scale(1);
}

.radio-button__fill {
  height: 16px;
  width: 16px;
  background: blue;
  border-radius: 50%;
  transition: all 0.2s;
  transform: scale(0);
}

.green {
  background: green;
}

.red {
  background: red;
}
