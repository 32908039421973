.wrapper {
  background: green;
  color: white;
  border-radius: 3px;
  margin: 10px 0;
  padding: 10px;
  position: relative;
  z-index: 10;
}

.back-icon {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.back-icon:hover {
  opacity: 0.5;
}

.red {
  background: red;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-family: var(--font-lato);
  font-weight: 700;
  padding: 4px;
}
