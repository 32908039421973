.icon {
  width: 1.5em;
  height: 1.5em;
  stroke-width: 2;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

input.range {
  appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: magenta;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 20px 20px;
  padding: 0;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--color-accent-action-medium);
  cursor: pointer;
}

.range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--color-accent-action-medium);
  cursor: pointer;
}

.explanation {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  column-gap: 5px;
  color: #7e7e7e;
}
