.title {
  font-size: 32px;
  font-weight: 400;
  margin: 16px 0 8px;
  line-height: 36px;
  color: magenta;
}
@media (min-width: 600px) {
  .title {
    margin-right: 150px;
    margin: 0 0 8px;
  }
}
