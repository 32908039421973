.title {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 25px;
  justify-content: center;
  color: black;
}

.subtitle {
  color: magenta;
  font-size: medium;
  color: magenta;
  margin-left: 10px;
  margin-bottom: 5px;
  margin-top: 1.2rem;
}

.heading {
  width: 415px;
  padding-top: 10px;
  padding-bottom: 0;
}

.confirm {
  background-color: magenta;
  margin-top: 1rem;
}
