.subtitle {
  font-size: 18px;
  line-height: 1.6em;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 0px;
  color: magenta;
}

@media (min-width: 600px) {
  .subtitle {
    margin-right: 12px;
  }
}
