.container {
  text-align: center;
  margin: 64px auto;
  max-width: 800px;
}

.noTopMargin {
  margin: 0 auto 32px;
}

.noTopMargin .header {
  margin-top: 0;
}

.simpleLayout {
  margin: 32px 0 0;
}

.header .blueSmall:after {
  content: '';
  position: absolute;
  left: calc(50% - 50px);
  bottom: 0;
  height: 1px;
  width: 100px;
  background-color: magenta;
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6em;
  margin: 2px 16px 0;
}

@media (max-width: 600px) {
  .text {
    font-size: 16px;
  }
  .blueSmall {
    margin: 48px 0;
  }

  .header .blueSmall {
    font-size: 28px;
    font-weight: 400;
    line-height: 24px;
    color: blue;
    margin-bottom: 16px;
    padding-bottom: 24px;
    position: relative;
  }
}
