.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 500px;
  width: 100%;
  padding: 10px;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.name-inputs {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subtitle {
  font-size: 1rem;
  width: 100%;
  color: magenta;
  justify-content: center;
  display: flex;
}

.label {
  color: magenta;
  font-weight: bold;
}

.profile-image-container {
  margin-right: 20px;
}

.marginBottom {
  margin-bottom: 8px;
}

@media (max-width: 1040px) {
  .top {
    flex-direction: column;
    min-width: 0;
  }
  .name-inputs {
    width: 100%;
  }
}
