.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip .tooltip-content {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 10px);
  z-index: 100000;
  background-color: var(--color-tooltip-bg);
  color: var(--color-tooltip-text);
  width: 100vw;
  max-width: 20rem;
  text-align: center;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.2));
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.5em;
  opacity: 0;
  transition: all 0.2s;
  border-radius: 4px;
  padding: 6px 14px;
  pointer-events: none;
  transform: translate(-50%, 50%) scale(0, 0);
  font-weight: normal;
}

.tooltip .tooltip-content::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: var(--color-tooltip-bg) transparent transparent transparent;
  left: calc(50% - 10px);
  top: 100%;
}

.tooltip.bottom-tooltip .tooltip-content {
  transform: translate(-50%, 0) scale(0, 0);
  bottom: initial;
  top: calc(100% + 10px);
}

.tooltip.bottom-tooltip .tooltip-content::after {
  border-color: transparent transparent var(--color-tooltip-bg) transparent;
  top: -20px;
}

.tooltip.bottom-left-tooltip .tooltip-content {
  transform: translate(-95%, 0) scale(0, 0);
  bottom: initial;
  top: calc(100% + 10px);
}

.tooltip.bottom-left-tooltip .tooltip-content::after {
  border-color: transparent transparent var(--color-tooltip-bg) transparent;
  top: -20px;
  left: calc(95% - 10px);
}

.tooltip.right-tooltip .tooltip-content {
  left: calc(100% + 10px);
  bottom: initial;
  top: 50%;
  transform: translate(0, -50%) scale(0, 0);
}

.tooltip.right-tooltip .tooltip-content::after {
  border-color: transparent var(--color-tooltip-bg) transparent transparent;
  left: -20px;
  top: calc(50% - 10px);
}

.tooltip.left-tooltip .tooltip-content {
  transform: translate(-120%, 0) scale(0, 0);
  bottom: initial;
  top: 50%;
}

.tooltip.left-tooltip .tooltip-content::after {
  border-color: transparent transparent transparent var(--color-tooltip-bg);
  left: calc(100% - 1px);
  top: calc(50% - 10px);
}

.tooltip:hover .tooltip-content {
  opacity: 1;
  transform: translate(-50%, 0) scale(1, 1);
}

.tooltip.bottom-tooltip:hover .tooltip-content {
  transform: translate(-50%, 0) scale(1, 1);
}

.tooltip.bottom-left-tooltip:hover .tooltip-content {
  transform: translate(-95%, 0) scale(1, 1);
}

.tooltip.right-tooltip:hover .tooltip-content {
  transform: translate(0, -50%) scale(1, 1);
}

.tooltip.left-tooltip:hover .tooltip-content {
  transform: translate(-120%, -50%) scale(1, 1);
}
