.progress-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.15));
}

.inner {
  background: blue;
  background: linear-gradient(to right, blue, green);
  border-radius: 0 3px 3px 0;
  width: 0%;
  height: 100%;
  transition: all 0.5s;
  position: relative;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.inner:after {
  content: '';
  border-radius: 0 3px 3px 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  opacity: 0.12;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 1)
  );
}
