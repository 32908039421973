.icon {
  width: 1.5em;
  height: 1.5em;
  stroke-width: 2;
  color: var(--color-icon-large);
}

.myPersonality {
  stroke-width: 1;
}

.print-link {
  text-decoration: none;
  color: var(--color-text-secondary);
  display: flex;
  justify-self: center;
  font-size: 0.7rem;
  align-self: center;
  transition: all 0.2s;
}

.print-link:hover {
  cursor: pointer;
  text-decoration: underline;
  transform: scale(1.2);
  color: var(--color-text-primary);
  text-decoration: none;
}

.card-content .trait:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

@media print {
  .yes-print {
    print-color-adjust: exact !important;
  }

  .no-print {
    display: none;
    height: 0;
    visibility: hidden;
  }

  .print-link {
    display: none;
  }
}

@media (max-width: var(--main-content-width)) {
  .sectionCard {
    max-width: 100%;
    width: calc(100vw - 40px);
    box-sizing: border-box;
    margin: 0 20px;
    overflow-x: hidden;
  }
}
