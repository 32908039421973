.container {
  border-radius: 50%;
  background: magenta;
  position: relative;
  border-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  transition: all 0.2s;
}

.container:hover .tooltip {
  opacity: 1;
  transform: scale(1);
}

.shadow {
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.2);
}
.container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.pi-tooltip {
  background-color: magenta;
  color: white;
  opacity: 0;
  transform: scale(0.8);
  position: absolute;
  bottom: 100%;
  left: 100%;
  right: unset;
  padding: 4px 6px;
  white-space: nowrap;
  border-radius: 4px;
  pointer-events: none;
  font-size: 18px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: all 0.1s;
  transform-origin: bottom left;
}

.tooltipLeft {
  left: unset;
  right: 100%;
}

.monochrome {
  filter: grayscale(100%);
}
.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: magenta;
}

.initials-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0;
}

.initial-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  background: magenta;
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  letter-spacing: 0;
}

@media print {
  .container {
    border-radius: 50%;
  }

  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .input {
    display: none;
  }
}
