.container {
  padding: 12px 0 64px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uncontained {
  display: block;
  align-items: inherit;
  justify-content: inherit;
  flex: inherit;
}
