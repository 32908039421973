.layout {
  display: flex;
  width: 932px;
  padding: 24px;
  gap: 24px;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

@media (max-width: 1080px) {
  .layout {
    width: 680px;
  }
}

@media (max-width: 768px) {
  .layout {
    width: 400px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullRow {
  display: block;
  width: 100%;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140.625%;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
