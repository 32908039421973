.questions {
  transition: min-height 0.25s;
}

.question-transition-previous,
.question-transition-next {
  opacity: 1;
}

.question-transition-container {
  position: absolute;
  top: 0;
  width: 100%;
}

.question-transition-current {
  transform: translateX(0);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}

.question-transition-previous {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}

.question-transition-next {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 400ms cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 9;
}
