.chart {
  display: grid;
  row-gap: 50px;
  padding: 10px 0;
}

.discLine {
  width: 100%;
  display: grid;
  grid-template-columns: 8em 1fr 8em;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.word {
  letter-spacing: 1.8px;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.word:first-child {
  text-align: right;
}
.word:last-child {
  text-align: left;
}

.line {
  display: flex;
  align-items: center;
  justify-self: center;

  position: relative;
  width: calc(100% - 36px);
  height: 100%;
  box-sizing: border-box;
}

.lineBar {
  height: 2px;
  width: 100%;
  background-color: var(--color-text-primary);
}

.lineIdentity {
  position: absolute;
}

.draggableContainer {
  position: absolute;
  top: -10px;
  margin-left: -18px;
}

.loadingArea {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
}

@media (max-width: 768px) {
  .chart {
    display: none;
  }
}
